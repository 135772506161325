.img-modal {
  display: none;
}

.img-modal-open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  background-color: #080108f5;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-touch-action: none;
  touch-action: none;
}

.img-container {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

img.modal-content {
  border-radius: .3em;
  display: block;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.prev,
.next {
  background-color: initial;
  border: none;
  color: var(--col-whp);
  cursor: pointer;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  font-size: 2.5em;
  outline: none;
}

.prev {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.close-modal {
  position: absolute;
  top: 3%;
  font-weight: bold;
  right: 7%;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--col-whp);
  background-color: transparent;
  border: none;
  -webkit-filter: drop-shadow(0 0 2em var(--col-whr));
  filter: drop-shadow(0 0 2em var(--col-whr));
}

.close-modal:hover {
  color: var(--col-violet-soft);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.prev:hover,
.next:hover {
  color: var(--col-whp);
  color: var(--col-violet-soft);
}

@media screen and (max-width: 850px) {

  .img-modal-open {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .img-container {
    -webkit-box-flex: 11;
    -ms-flex: 11;
    flex: 11;
  }

  .prev,
  .next {
    font-size: 2em;
  }

  .close-modal {
    right: 2%;
    top: 11%;
  }

}