.topnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--col-rd);
  position: fixed;
  width: 100%;
  top: 0;
  opacity: 0.9;
  z-index: 9;
}

.header-logo {
  border-radius: 3em;
  padding: 0.4em;
  margin-left: 2em;
}

.nav-link_1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  color: var(--col-whr);
}

.social_media {
  padding: 0.5em;
  margin-right: 2em;
  text-align: center;
  color: var(--col-whr);
  text-decoration: none;
  letter-spacing: 0.3em;
  cursor: pointer;
}

.nav-link_2 svg {
  font-size: 1.8em;
}

.social_media i {
  font-size: 0.8em;
}

.nav-link_2 svg:hover {
  /* letter-spacing: .5em; */
  font-size: 2em;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.header-logo:hover {
  padding: 0.2em;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.nav-link_2:active {
  letter-spacing: 1em;
}

@media screen and (max-width: 850px) {
  .social_media {
    margin-right: .4em;
  }
}