.player {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 85%;
  height: 100vh;
  margin: 0 auto;
  border-radius: 0.5rem;
  text-align: center;
  gap: 1em;
}

.player_tv {
  width: 16em;
  height: 16em;
  border: 1em solid #212121;
  border-radius: 0.5rem;
  background-color: #212121;
  padding: 1em;
  -webkit-filter: drop-shadow(0 0 2em #da99f883);
  filter: drop-shadow(0 0 2em #da99f883);
  background-repeat: no-repeat;
  background-size: cover;
}

.tv_off {
  background-image: url("https://media4.giphy.com/media/vuDebpxeqF95dy4k7v/giphy.gif");
}

.tv_on {
  background-image: url("https://media.giphy.com/media/L3wnA6ONOWkQl2rcwI/giphy.gif");
  background-position: center;
}

.player_one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 85%;
  /* height: 100vh; */
  margin: 0 auto;
  gap: 1em;
}

#controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 65%;
}

#controls p {
  font-size: 0.8em;
  text-shadow: 3px 1px 1px var(--col-rd);
  margin-top: 0;
  letter-spacing: 0.3em;
  -webkit-animation: 3s anim-songTitle ease-out infinite;
  animation: 3s anim-songTitle ease-out infinite;
}

@-webkit-keyframes anim-songTitle {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    text-shadow: 1px 3px var(--col-rd);
  }

  20% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.7;
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes anim-songTitle {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    text-shadow: 1px 3px var(--col-rd);
  }

  20% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.7;
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.controls_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.5em;
}

.controls_wrapper button {
  height: 2em;
  cursor: pointer;
  color: var(--col-whp);
  margin: 0.3em;
  border-radius: 0.5em;
  background-color: #212121;
  -webkit-box-shadow: 1px 1px 1px #653e78;
  box-shadow: 1px 1px 1px #99939c;
  outline: none;
}

.controls_wrapper button:hover {
  -webkit-filter: drop-shadow(0 0 2em #da99f8f1);
  filter: drop-shadow(0 0 2em #da99f8f1);
}

.controls_wrapper button:active {
  -webkit-box-shadow: 2px 2px 2px #212121;
  box-shadow: 2px 2px 2px #212121;
  /* padding-right: 0.7em; */
}

button.playBtn {
  background-color: var(--col-rd);
}

button.playBtn:active {
  background-color: transparent;
}

.volume-onOff {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1em;
}

button.volume-onOff {
  background-color: var(--col-rd);
  color: var(--col-whp);
  border-radius: 0.5em;
  cursor: pointer;
}

.radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.7em;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  /* filter: drop-shadow(0 0 2em #da99f883); */
}

.progress_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 1em auto;
}

.progress-container {
  margin: 0.3em 0;
  height: 1.5em;
  width: 100%;
  cursor: pointer;
  border-radius: 0.3em;
}

.progress_section span {
  font-size: 0.6em;
  letter-spacing: normal;
  margin: 0 0.5em;
}

/* volume slider styles */
.volume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.5em;
  width: 100%;
}

.volume span {
  font-size: 1em;
  padding: 0 0.2em;
}

.slider-vol-container {
  width: 100%;
  height: 1em;
  border-bottom: 1px solid var(--col-whp);
  cursor: pointer;
}

#volumeSlider {
  cursor: pointer;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 100%;
    height: 1em;
    border-radius: 0.2em;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    background-color: var(--col-whr);
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 1em;
    appearance: none;
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 1em;
    height: 1em;
    background: #0c0c0c;
    -webkit-appearance: none;
    /* margin-top: -0.3em; */
    -webkit-box-shadow: -1000px 0 0 1000px var(--col-rd);
    box-shadow: -1000px 0 0 1000px var(--col-rd);
  }
}


/* progress bar */

#progress-bar {
  width: 100%;
  height: 20px;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background-color: var(--col-whr);
  color: var(--col-rd);
  cursor: pointer;
}

progress::-moz-progress-bar {
  background: var(--col-rd);
}

progress::-webkit-progress-value {
  background: var(--col-rd);
}

/* volume slider styles */

#currentSong {
  text-align: center;
  font-size: var(--fs-400);
  overflow: hidden;
}

#songList {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  /* flex-wrap: wrap; */
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /* -ms-flex-direction: column;
  flex-direction: column; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1em;
  border-radius: 0.7em;
}

#songList button {
  cursor: pointer;
  /* font-size: var(--fs-400); */
  font-size: .9em;
  font-family: "Nanum Gothic", sans-serif;
  letter-spacing: 0.3em;
  background-color: transparent;
  border: none;
  color: var(--col-whr);
  text-shadow: 4px 1px var(--col-rd);
  /* border-bottom: 1px solid var(--col-whr); */
}

#songList button:hover {
  /* background: #ff0052; */
  -webkit-box-shadow: 0 6px 16px var(--col-rd);
  box-shadow: 0 6px 16px var(--col-rd);
  border-radius: 8em 1.5em;
  padding: 0.5em;
  opacity: 0.8;
  -webkit-filter: drop-shadow(0 0 2em #da99f8c5);
  filter: drop-shadow(0 0 2em #da99f8c5);
}

#songList button:focus {
  background: var(--col-whr);
  border-radius: 13em 1.5em;
}

#songList button a {
  text-decoration: none;
  color: var(--col-whr);
}

.music_author {
  font-size: 0.6em;
  padding: 0.5em;
  font-weight: 100;
  /* margin-top: 2em; */
}

.music_author a {
  color: #ff0052;
  text-shadow: 2px 2px #3f3d41;
  margin: 0.3em;
  letter-spacing: 0.2em;
  text-decoration: none;
}

.btn-down {
  display: none;
  background-color: transparent;
  color: var(--col-whr);
  border: none;
  border-radius: 0.5em;
  padding: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 4px 1px var(--col-rd);
  box-shadow: 4px 1px var(--col-rd);
  -webkit-animation: narrow 11s infinite alternate;
  animation: bigpre 11s infinite alternate;
  position: absolute;
  right: 5%;
  bottom: 10%;
  cursor: pointer;
}

button.btn-down svg:hover {
  -webkit-box-shadow: 2px 1px var(--col-whr);
  box-shadow: 2px 1px var(--col-whr);
  background-color: var(--col-whr);
  color: var(--col-rd);
  font-size: 2.5em;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

button.btn-down svg {
  display: block;
  color: var(--col-whr);
  text-decoration: none;
  font-size: 2em;
  border-radius: 33em;
}

@media (max-width: 850px) {
  .player {
    /* display: block; */
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  .player_one {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /* width: 100%; */
  }

  #songList,
  .radio {
    height: 100vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1.3em;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }

  #songList button {
    font-size: 0.9em;
  }

  #controls {
    width: 75%;
  }

  .progress-container {
    margin: 1em auto;
  }

  .controls_wrapper button {
    margin: 0.3em 0.7em;
  }

  .btn-down {
    display: block;
  }
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: none;
  }
}