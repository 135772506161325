/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap");

:root {
  color-scheme: light only;
  --fs-600: 2rem;
  --fs-500: 1.2rem;
  --fs-400: 1rem;
  --col-body-bgr: #22010f;
  --col-whr: #dacbd3;
  --col-whp: #f7e4e9;
  --col-rd: #270114;
  --col-rd-soft: #290d42;
  --col-app-bgnd: #050103cf;
  --col-scroll-barTrack: hsla(279, 70%, 4%, 0.81);
  --col-scroll-barHover: hsl(0, 0%, 0%);
  --col-scrollbar-color: hsla(220 67% 4% 0.81 / 1) hsla(194, 66%, 44%, 0.782);
  --col-anima-text: #132435;
  --col-btn-border: #3e3d3d;
}

::-webkit-scrollbar {
  width: 0.7em;
}

::-webkit-scrollbar-track {
  /* background: hsl(211 82% 11% / 1); */
  background: var(--col-scroll-barTrack);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: var(--col-rd);
  border-radius: 100vw;
  /* border: .25em solid hsl(211 82% 11% / 1); */
}

::-webkit-scrollbar-thumb:hover {
  background: --col-scroll-barHover;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: var(--col-scrollbar-color);
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-family: "Nanum Gothic", sans-serif;
  background-color: var(--col-body-bgr);
  /* background-image: url("./img/img-computer.png"); */
  background-position: center;
  background-repeat: repeat;
  font-size: 16px;
  color: var(--col-whr);
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: auto;
  font-weight: bolder;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}