footer {
  background-color: var(--col-rd);
  opacity: 0.9;
  padding: 0.4em;
  margin: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
}

.smedia {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.smedia p {
  letter-spacing: 0.2em;
  font-size: 0.9em;
  margin: 0;
  font-weight: lighter;
  padding: 0.3em;
}

.smedia a {
  text-decoration: none;
  color: var(--col-whr);
}

.smedia a button {
  background-color: var(--col-rd);
  color: var(--col-whr);
  cursor: pointer;
  border-radius: .3em;
  border-color: var(--col-btn-border);
}

.smedia a button:hover {
  filter: drop-shadow(.1em .1em .3em var(--col-whr));
}

.smedia:hover,
.smedia a:hover {
  color: var(--col-anima-text);
  letter-spacing: 0.5em;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

@media screen and (max-width: 800px) {
  footer {
    padding: 0.6em 0.2em;
  }
}