.smedia-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* flex: 1 1; */
  gap: 1em;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.smedia-contact a {
  text-decoration: none;
  color: var(--col-whp);
  font-size: var(--fs-500);
  /* opacity: 0.6; */
  letter-spacing: 0.2em;
  -webkit-filter: drop-shadow(1em 0em 2em var(--col-whr));
  filter: drop-shadow(1em 0em 2em var(--col-whr));
}

.smedia-contact svg {
  -webkit-filter: drop-shadow(0 0 2em var(--col-whr));
  filter: drop-shadow(0 0 2em var(--col-whr));
  font-size: var(--fs-600);
  margin: 0 0.3em;
}

.smedia-contact h3 {
  -webkit-filter: drop-shadow(0 0 2em var(--col-whr));
  filter: drop-shadow(0 0 2em var(--col-whr));
}

.smedia-contact a:hover {
  color: var(--col-rd);
}

@media screen and (max-width: 800px) {
  .smedia-contact {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 65%;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    gap: 1.8em;
  }
}