.App {
  background-color: var(--col-app-bgnd);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  opacity: 0.9;
}

.p_description {
  text-align: center;
  padding: 0 1em;
  width: 65%;
  line-height: 1.5em;
  letter-spacing: 0.2em;
  font-size: 0.9em;
  margin: 2em auto;
  -webkit-animation: bigpre 11s infinite alternate;
  animation: bigpre 11s infinite alternate;
}

button.btn-back {
  /* margin: 0 auto 1em; */
  background-color: transparent;
  color: var(--col-whr);
  border: none;
  border-radius: 0.5em;
  padding: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  -webkit-animation: arrow 11s infinite alternate;
  animation: bigpre 11s infinite alternate;
  position: fixed;
  left: 3%;
  /* bottom: 6%; */
  top: 15%;
}

button.btn-back svg:hover {
  -webkit-box-shadow: 2px 1px var(--col-whr);
  box-shadow: 2px 1px var(--col-whr);
  background-color: var(--col-whr);
  color: var(--col-rd);
  font-size: 2.1em;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

button.btn-back svg {
  display: block;
  color: var(--col-whr);
  text-decoration: none;
  font-size: 1.7em;
  border-radius: 33em;
}

@-webkit-keyframes arrow {
  50% {
    font-size: 2em;
    color: var(--col-rd);
    text-shadow: -4px -1px var(--col-whr);
  }
}

@keyframes arrow {
  50% {
    font-size: 2em;
    color: var(--col-rd);
    text-shadow: -4px -1px var(--col-whr);
  }
}

.close-modal {
  position: absolute;
  top: 7%;
  right: 11%;
  font-size: 2em;
  cursor: pointer;
  color: var(--col-whp);
  background-color: transparent;
  border: none;
  -webkit-filter: drop-shadow(0 0 2em var(--col-whr));
  filter: drop-shadow(0 0 2em var(--col-whr));
}

.close-modal:hover {
  font-size: 2.1em;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: none;
  }
}

@media screen and (max-width: 850px) {
  .close-modal {
    right: 3%;
  }

  .p_description {
    width: 95%;
  }
}