.img_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80%;
  gap: 2em;
  margin: 1.5em auto;
}

.decoration_words {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 1em;
  width: 80%;
  z-index: 2;
}

.img {
  border-radius: 3em;
  margin: 0 auto;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 11s linear infinite;
  opacity: 11;
  z-index: 1;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.decoration_words .item {
  font-size: var(--fs-500);
  color: var(--col-whr);
  line-height: 1.4rem;
  letter-spacing: 0.6em;
  /* text-shadow: 4px 1px var(--col-rd); */
  text-shadow: 4px 1px var(--col-anima-text);
  text-decoration: none;
  -webkit-animation: bigpre 11s infinite alternate;
  animation: bigpre 11s infinite alternate;
  padding: 0.2em;
  z-index: 2;
}

.item:hover {
  letter-spacing: 0.8em;
  -webkit-transition: 1.1s;
  -o-transition: 1.1s;
  transition: 1.1s;
  color: var(--col-rd);
  text-shadow: 4px 1px var(--col-anima-text);
  -webkit-filter: drop-shadow(0 0 2em var(--col-anima-text));
  filter: drop-shadow(0 0 2em var(--col-anima-text));
}

@-webkit-keyframes bigpre {
  50% {
    font-size: var(--fs-400);
    color: var(--col-anima-text);
    text-shadow: -4px -1px var(--col-whr);
  }
}

@keyframes bigpre {
  50% {
    font-size: var(--fs-400);
    color: var(--col-anima-text);
    text-shadow: -4px -1px var(--col-whr);
  }

  100% {
    font-size: var(--fs-500);
    color: var(--col-whr);
  }
}

.item_2 {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.item_3 {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

@media screen and (max-width: 800px) {

  .item_2,
  .item_3 {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .img_container {
    width: 100%;
    height: auto;
    margin: 1em auto;
  }

  .decoration_words {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* margin: 1em auto; */
    gap: 1.5em;
  }

  .decoration_words .item {
    font-size: 0.9rem;
  }
}