.main-gy-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 100%;
  margin-top: 67px;
  padding-bottom: 2em;
}

.gallery-title {
  font-size: 1.5em;
  text-align: center;
  margin: 0;
  position: fixed;
  top: 0;
  opacity: 0.7;
  padding: 0.8em 0;
  z-index: 11;
}

.gy-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 85%;
  padding: 1.5em;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1em;
  margin: 1.5em;
  margin-bottom: 0;
}

.gy-item {
  width: 14rem;
  /* border: 4px solid #0c0c0c; */
  border-radius: 0.4em;
  cursor: pointer;
}

.gy-item:hover {
  -webkit-filter: drop-shadow(0 0 2em var(--col-whr));
  filter: drop-shadow(0 0 2em var(--col-whr));
}

.gy-img {
  width: 100%;
  aspect-ratio: 1 / 1;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.4em;
  height: 100%;
}

.svg-inline--fa {
  margin: 0 0.5em;
}

@media screen and (max-width: 850px) {
  .main-gy-container {
    padding-bottom: 3em;
  }

  .gy-container {
    width: 100%;
  }

  .gy-item {
    width: 41%;
  }

  .header-logo {
    margin-left: 0.5em;
  }

  .nav-link_2 {
    padding: 0.5em;
  }

  .gallery-title {
    font-size: 1.1em;
    top: 1%;
  }

}